import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-classes"></a><h2>Psionic Classes
    </h2>
    <a id="soulknife"></a><h3>SOULKNIFE
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/xph_gallery/80449.jpg" style={{
      "fontWeight": "bold"
    }}><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a> <span style={{
      "fontWeight": "bold"
    }}>Alignment:</span> Any.
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d10.
    <h6>Class Skills</h6>
The soulknife&#8217;s class skills (and the key ability for each skill) are <a href="psionicSkills.html#autohypnosis" style={{
      "color": "rgb(87, 158, 182)"
    }}>Autohypnosis</a>* (Wis), <a href="skillsAll.html#climb" style={{
      "color": "rgb(87, 158, 182)"
    }}>Climb</a>
(Str), <a href="psionicSkills.html#concentration" style={{
      "color": "rgb(87, 158, 182)"
    }}>Concentration</a>* (Con), <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
(Int), <a href="skillsAll.html#hide" style={{
      "color": "rgb(87, 158, 182)"
    }}>Hide</a>
(Dex), <a href="skillsAll.html#jump" style={{
      "color": "rgb(87, 158, 182)"
    }}>Jump</a>
(Str), <a href="psionicSkills.html#knowledge-psionics" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge (psionics)</a>* (Int), <a href="skillsAll.html#listen" style={{
      "color": "rgb(87, 158, 182)"
    }}>Listen</a>
(Wis), <a href="skillsAll.html#move-silently" style={{
      "color": "rgb(87, 158, 182)"
    }}>Move
Silently</a> (Dex), <a href="skillsAll.html#profession" style={{
      "color": "rgb(87, 158, 182)"
    }}>Profession</a> (Wis), <a href="skillsAll.html#spot" style={{
      "color": "rgb(87, 158, 182)"
    }}>Spot</a>
(Wis), and <a href="skillsAll.html#tumble" style={{
      "color": "rgb(87, 158, 182)"
    }}>Tumble</a> (Dex).
*New skill or expanded use of existing skill.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at 1st Level:</span>
(4 + Int modifier) x4.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier.
    </p>
    <a id="table-the-soulknife"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Soulknife</span>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="1" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th rowSpan="1" style={{
            "width": "6%"
          }}>Will
Save</th>
          <th rowSpan="1" style={{
            "width": "40%"
          }}>Special</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+2</td>
          <td>Mind blade, Weapon Focus (mind blade), Wild Talent
          </td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+3</td>
          <td>+3</td>
          <td>Throw mind blade</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>Psychic strike +1d8</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1 mind blade</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>Free draw, shape mind blade</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>Mind blade enhancement +1, Speed of Thought</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>Psychic strike +2d8</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+2</td>
          <td>+6</td>
          <td>+6</td>
          <td style={{
            "fontStyle": "italic"
          }}>+2 mind blade</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>Bladewind, Greater Weapon Focus (mind blade)</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+3</td>
          <td>+7</td>
          <td>+7</td>
          <td>Mind blade enhancement +2</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>+7</td>
          <td>Psychic strike +3d8</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>+8</td>
          <td style={{
            "fontStyle": "italic"
          }}>+3 mind blade</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>+8</td>
          <td>Knife to the soul</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+4</td>
          <td>+9</td>
          <td>+9</td>
          <td>Mind blade enhancement +3</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+5</td>
          <td>+9</td>
          <td>+9</td>
          <td>Psychic strike +4d8</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+10</td>
          <td>+10</td>
          <td style={{
            "fontStyle": "italic"
          }}>+4 mind blade</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+10</td>
          <td>+10</td>
          <td>Multiple throw</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+6</td>
          <td>+11</td>
          <td>+11</td>
          <td>Mind blade enhancement +4</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+6</td>
          <td>+11</td>
          <td>+11</td>
          <td>Psychic strike +5d8</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">+12</td>
          <td className="last-row" style={{
            "fontStyle": "italic"
          }}>+5 mind
blade</td>
        </tr>
      </tbody>
    </table>
    <a id="soulknife-class-features"></a><h6>Class Features</h6>
All the following are class features of the soulknife.
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
Soulknives are proficient with all simple weapons, with their own mind
blades, and with light armor and shields (except tower shields).</p>
    <a id="soulknife-mind-blade"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mind Blade (Su):</span> As a
move action, a soulknife can create a semisolid blade composed of
psychic energy distilled from his own mind. The blade is identical in
all ways (except visually) to a short sword of a size appropriate for
its wielder. For instance, a Medium soulknife materializes a Medium
mind blade that he can wield as a light weapon, and the blade deals 1d6
points of damage (crit 19-20/x2). Soulknives who are smaller or larger
than Medium create mind blades identical to short swords appropriate
for their size, with a corresponding change to the blade&#8217;s damage. The
wielder of a mind blade gains the usual benefits to his attack roll and
damage roll from a high Strength bonus.
The blade can be broken (it has hardness 10 and 10 hit points);
however, a soulknife can simply create another on his next move action.
The moment he relinquishes his grip on his blade, it dissipates (unless
he intends to throw it; see below). A mind blade is considered a magic
weapon for the purpose of overcoming damage reduction.
A soulknife can use feats such as <a href="featsAll.html#power-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Power Attack</a> or <a href="skillsAll.html#combat-expertise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat Expertise</a> in
conjunction with the mind blade just as if it were a normal weapon. He
can also choose mind blade for feats requiring a specific weapon
choice, such as <a href="featsAll.html#weapon-specialization" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Specialization</a>. Powers or
spells that upgrade
weapons can be used on a mind blade.
A soulknife&#8217;s mind blade improves as the character gains higher levels.
At 4th level and every four levels thereafter, the mind blade gains a
cumulative +1 enhancement bonus on attack rolls and damage rolls (+2 at
8th level, +3 at 12th level, +4 at 16th level, and +5 at 20th level).
Even in places where psionic effects do not normally function (such as
within a <a href="psionicPowersGtoP.html#null-psionic-field" style={{
        "color": "rgb(87, 158, 182)"
      }}>null psionics field</a>), a
soulknife can attempt to sustain his
mind blade by making a DC 20 Will save. On a successful save, the
soulknife maintains his mind blade for a number of rounds equal to his
class level before he needs to check again. On an unsuccessful attempt,
the mind blade vanishes. As a move action on his turn, the soulknife
can attempt a new Will save to rematerialize his mind blade while he
remains within the psionics negating effect.</p>
    <a id="soulknife-weapon-focus-mind-blade"></a><p><span style={{
        "fontWeight": "bold"
      }}>Weapon Focus (Mind Blade):</span>
A soulknife gains <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> (mind blade) as a
bonus feat.</p>
    <a id="soulknife-wild-talent"></a><p><span style={{
        "fontWeight": "bold"
      }}>Wild Talent:</span> A
soulknife gains <a href="psionicFeats.html#wild-talent" style={{
        "color": "rgb(87, 158, 182)"
      }}>Wild Talent</a> as a bonus feat.
(This class feature
provides the character with the psionic power he needs to materialize
his mind blade, if he has no power points otherwise.)</p>
    <a id="soulknife-throw-mind-blade"></a><p><span style={{
        "fontWeight": "bold"
      }}>Throw Mind Blade (Ex):</span>
A soul knife of 2nd level or higher can throw his mind blade as a
ranged weapon with a range increment of 30 feet.
Whether or not the attack hits, a thrown mind blade then dissipates. A
soulknife of 3rd level or higher can make a psychic strike (see below)
with a thrown mind blade and can use the blade in conjunction with
other special abilities (such as Knife to the Soul; see below).</p>
    <a id="soulknife-psychic-strike"></a><p><span style={{
        "fontWeight": "bold"
      }}>Psychic Strike (Su):</span>
As a move action, a soulknife of 3rd level or higher can imbue his mind
blade with destructive psychic energy. This effect deals an extra 1d8
points of damage to the next living, nonmindless target he successfully
hits with a melee attack (or ranged attack, if he is using the throw
mind blade ability). Creatures immune to mind-affecting effects are
immune to psychic strike damage. (Unlike the rogue&#8217;s sneak attack, the
psychic strike is not precision damage and can affect creatures
otherwise immune to extra damage from critical hits or more than 30
feet away, provided they are living, nonmindless creatures not immune
to mind-affecting effects.)
A mind blade deals this extra damage only once when this ability is
called upon, but a soulknife can imbue his mind blade with psychic
energy again by taking another move action.
Once a soulknife has prepared his blade for a psychic strike, it holds
the extra energy until it is used. Even if the soulknife drops the mind
blade (or it otherwise dissipates, such as when it is thrown and
misses), it is still imbued with psychic energy when the soulknife next
materializes it.
At every four levels beyond 3rd (7th, 11th, 15th, and 19th), the extra
damage from a soulknife&#8217;s psychic strike increases as shown on the
Table above.</p>
    <a id="soulknife-free-draw"></a><p><span style={{
        "fontWeight": "bold"
      }}>Free Draw (Su):</span> At 5th
level, a soulknife becomes able to materialize his mind blade as a free
action instead of a move action. He can make only one attempt to
materialize the mind blade per round, however.</p>
    <a id="soulknife-shape-mind-blade"></a><p><span style={{
        "fontWeight": "bold"
      }}>Shape Mind Blade (Su):</span>
At 5th level, a soulknife gains the ability to change the form of his
mind blade. As a fullround action, he can change his mind blade to
replicate a longsword (damage 1d8 for a Medium weapon wielded as a
one-handed weapon) or a bastard sword (damage 1d10 for a Medium weapon,
but he must wield it as a two-handed weapon unless he knows the <a href="featsAll.html#exotic-weapon-proficiency" style={{
        "color": "rgb(87, 158, 182)"
      }}>Exotic
Weapon Proficiency</a> (bastard sword) feat). If a soulknife shapes his
mind blade into the form of a bastard sword and wields it two-handed,
he adds 1-1/2 times his Strength bonus to his damage rolls, just like
when using any other two-handed weapon.
Alternatively, a soulknife can split his mind blade into two identical
short swords, suitable for fighting with a weapon in each hand. (The
normal penalties for fighting with two weapons apply.) However, both
mind blades have an enhancement bonus 1 lower than the soulknife would
otherwise create with a single mind blade.</p>
    <a id="soulknife-mind-blade-enhancement"></a><p><span style={{
        "fontWeight": "bold"
      }}>Mind Blade Enhancement (Su):</span>
At 6th level, a soulknife gains the ability to enhance his mind blade.
He can add any one of the <a href="magicItemsAW.html#weapon-special-abilities" style={{
        "color": "rgb(87, 158, 182)"
      }}>weapon special abilities</a> on the
table below
that has an enhancement bonus value of +1.
At every four levels beyond 6th (10th, 14th, and 18th), the value of
the enhancement a soulknife can add to his weapon improves to +2, +3,
and +4, respectively. A soulknife can choose any combination of weapon
special abilities that does not exceed the total allowed by the
soulknife&#8217;s level.
The weapon ability or abilities remain the same every time the
soulknife materializes his mind blade (unless he decides to reassign
its abilities; see below). The ability or abilities apply to any form
the mind blade takes, including the use of the shape mind blade or
bladewind class abilities.</p>
    <a id="table-mind-blade-enhancements"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>Weapon Special Ability
          </th>
          <th>Enhancement Bonus Value
          </th>
        </tr>
        <tr className="odd-row">
          <td>Defending
          </td>
          <td>+1
          </td>
        </tr>
        <tr>
          <td>Keen</td>
          <td>+1
          </td>
        </tr>
        <tr className="odd-row">
          <td>Lucky*</td>
          <td>+1
          </td>
        </tr>
        <tr>
          <td>Mighty cleaving</td>
          <td>+1
          </td>
        </tr>
        <tr className="odd-row">
          <td>Psychokinetic*</td>
          <td>+1
          </td>
        </tr>
        <tr>
          <td>Sundering*</td>
          <td>+1
          </td>
        </tr>
        <tr className="odd-row">
          <td>Vicious</td>
          <td>+1
          </td>
        </tr>
        <tr>
          <td>Collision*</td>
          <td>+2
          </td>
        </tr>
        <tr className="odd-row">
          <td>Mindcrusher*</td>
          <td>+2
          </td>
        </tr>
        <tr>
          <td className="last-row">Psychokinetic burst*</td>
          <td className="last-row">+2
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Suppression*</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>+2
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Wounding</td>
          <td style={{
            "verticalAlign": "top"
          }}>+2
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Bodyfeeder*</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>+3
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}>Mindfeeder*</td>
          <td style={{
            "verticalAlign": "top"
          }}>+3
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Soulbreaker*</td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>+3
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="2">*<span style={{
              "fontStyle": "italic"
            }}>New special abilities</span>
          </td>
        </tr>
      </tbody>
    </table>
    <p>A soulknife can reassign the ability or abilities he has added
to his mind blade. To do so, he must first spend 8 hours in
concentration. After that period, the mind blade materializes with the
new ability or abilities selected by the soulknife.</p>
    <a id="soulknife-speed-of-thought"></a><p><span style={{
        "fontWeight": "bold"
      }}>Speed of Thought:</span> A
soulknife gains <a href="psionicFeats.html#speed-of-thought" style={{
        "color": "rgb(87, 158, 182)"
      }}>Speed of Thought</a> as a bonus feat
at 6th level.</p>
    <a id="soulknife-bladewind"></a><p><span style={{
        "fontWeight": "bold"
      }}>Bladewind (Su):</span> At 9th
level, a soulknife gains the ability to momentarily fragment his mind
blade into numerous identical blades, each of which strikes at a nearby
opponent.
As a full attack, when wielding his mind blade, a soulknife can give up
his regular attacks and instead fragment his mind blade to make one
melee attack at his full base attack bonus against each opponent within
reach. Each fragment functions identically to the soulknife&#8217;s regular
mind blade.
When using bladewind, a soulknife forfeits any bonus or extra attacks
granted by other feats or abilities (such as the <a href="featsAll.html#cleave" style={{
        "color": "rgb(87, 158, 182)"
      }}>Cleave</a>
feat or the <a href="spellsHtoL.html#haste" style={{
        "color": "rgb(87, 158, 182)"
      }}>haste</a>
spell).
The mind blade immediately reverts to its previous form after the
bladewind attack.</p>
    <a id="soulknife-greater-weapon-focus-mind-blade"></a><p><span style={{
        "fontWeight": "bold"
      }}>Greater Weapon Focus (Mind
Blade):</span> A soulknife gains <a href="featsAll.html#greater-weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Greater Weapon Focus</a> (mind
blade) as a
bonus feat at 9th level.</p>
    <a id="soulknife-knife-to-the-soul"></a><p><span style={{
        "fontWeight": "bold"
      }}>Knife to the Soul (Su):</span>
Beginning at 13th level, when a soulknife executes a psychic strike, he
can choose to substitute Intelligence, Wisdom, or Charisma damage (his
choice) for extra dice of damage. For each die of extra damage he gives
up, he deals 1 point of damage to the ability score he chooses. A
soulknife can combine extra dice of damage and ability damage in any
combination.
The soulknife decides which ability score his psychic strike damages
and the division of ability damage and extra dice of damage when he
imbues his mind blade with the psychic strike energy.</p>
    <a id="soulknife-multiple-throw"></a><p><span style={{
        "fontWeight": "bold"
      }}>Multiple Throw (Ex):</span>
At 17th level and higher, a soulknife can throw a number of mind blades
per round equal to the number of melee attacks he could make.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      